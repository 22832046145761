<template>
    <div class="base-content">
        <div class="base-box"
             v-loading="$store.state.allLoading"
             element-loading-text="加载中..."
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="reg-box">
                <div class="back-btn" @click="onBack">
                    <i class="el-icon-back"></i>返回上一级
                </div>
                <div class="volunteer-box">
                    <div class="download_area2">
                        <div class="download_info">
                            <i class="red">*</i>本次考试采用在指定考点机房进行线上机考，请及时下载模拟练习软件熟悉机考流程及答题方式。
                        </div>
                        <div class="download_info">
                            模拟练习软件及操作说明下载链接：<span @click="downloadZip">点击下载</span>
                        </div>
                        <div class="download_info">
                            准考证下载链接：<span @click="downloadPdf">点击下载</span>
                        </div>
                    </div>

                    <div class="pdf_area">
                        <pdf-area :type="1"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PdfArea from "c/index/PdfArea";
    import {downloadZip, downloadPdf} from "r/index/pdf.js";

    export default {
        name: "main-pdf",
        components: {PdfArea},
        data() {
            return {
                bm_num: null,
            }
        },
        created() {
            this.bm_num = this.$route.query.bm_num;
        },
        methods: {
            // 返回上一级按钮
            onBack() {
                this.$router.replace('/main/index')
            },
            // 下载练习软件
            async downloadZip() {
                let {data: res} = await downloadZip({
                    bm_num: this.bm_num,
                    type: 1,
                })
                // console.log(res)
                if (res.code != 200) {
                    return this.$message.error(res.message);
                }
                window.location.href = res.data;
            },
            // 下载pdf
            async downloadPdf() {
                let {data: res} = await downloadPdf({
                    bm_num: this.bm_num,
                    type: 1,
                })
                // console.log(res)
                if (res.code != 200) {
                    return this.$message.error(res.message);
                }
                window.open(res.data, '_blank');
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/base.scss";

    .back-btn {
        position: absolute;
        left: 15px;
        top: 15px;
        color: var(--color);

        i {
            color: var(--color);

        }

        &:hover {
            cursor: pointer;
            font-weight: bold;

            i {
                font-weight: bold;

            }
        }
    }

    .download_area2 {
        font-family: "Microsoft YaHei";
        padding: 10px;
        background-color: #f0f0f0;

        .download_info {
            font-size: 14px;
            color: #333333;
            line-height: 30px;

            > span {
                color: #e0823d;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .pdf_area {
        color: #000000;
        font-family: "华文仿宋";
        width: 754px;
        height: 1082px;
        padding: 20px;
        margin: 30px auto 0;
        background-color: #ffffff;
    }
</style>

