<template>
    <div class="pdf_box">
        <div class="title_big">西安交通大学2024年强基计划初试准考证</div>
        <!--个人详情区域-->
        <div class="student_info">
            <div class="top">
                <div class="lf">
                    <div class="student_info_item">
                        <div class="text_bold">准考证号</div>
                        <div class="text_nol">{{dataInfo.zkz_num}}</div>
                    </div>
                    <div class="student_info_item">
                        <div class="text_bold">姓名</div>
                        <div class="text_nol">{{dataInfo.name}}</div>
                    </div>
                    <div class="student_info_item">
                        <div class="text_bold">证件号码</div>
                        <div class="text_nol">{{dataInfo.sfz}}</div>
                    </div>
                    <div class="student_info_item">
                        <div class="text_bold">报名号</div>
                        <div class="text_nol">{{dataInfo.bm_num}}</div>
                    </div>
                    <div class="student_info_item">
                        <div class="text_bold">报考院校</div>
                        <div class="text_nol">{{dataInfo.university_name}}</div>
                    </div>
                    <div class="student_info_item">
                        <div class="text_bold">考试地点</div>
                        <div class="text_nol">{{dataInfo.examRoomName}}</div>
                    </div>
                </div>
                <div class="rt">
                    <img id="avatar" :src="dataInfo.avatar"/>
                </div>
            </div>
            <div class="student_info_item">
                <div class="text_bold">考点地址</div>
                <div class="text_nol big">{{dataInfo.examRoomAddress}}</div>
            </div>
        </div>
        <div style="clear:both;"></div>
        <!--table区域-->
        <table class="student_table" id="student_table" cellspacing="0">
            <thead>
            <tr>
                <th>时间</th>
                <th>科目</th>
                <th>机房位置</th>
                <th>机号</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in tableList" :key="index">
                <td>{{item.time}}</td>
                <td>{{item.subject}}</td>
                <td>{{item.location}}</td>
                <td>{{item.pc_no}}</td>
            </tr>
            </tbody>
        </table>
        <!--考生须知-->
        <div class="exam_notice">
            <div class="exam_notice_tit">考试须知：</div>
            <!--笔试须知-->
            <div class="exam_notice_txt">1. 诚信考试，服从监考人员管理，遵守考场秩序。</div>
            <div class="exam_notice_txt">2. 凭《准考证》和身份证原件，按规定时间和地点参加考试。</div>
            <div class="exam_notice_txt">3.
                考生入场，除有效身份证件、准考证、签字笔外，其他任何物品不准带入考场。严禁携带各种通讯工具(如手机、寻呼机、智能穿戴设备及其他无线接收、传送设备等)、电子存储记忆录放设备进入考场。已携带入场的应按照要求存放在考场指定位置(携带的通讯工具、电子设备等应全部关闭后，再存放到考场指定位置)。在整个考试期间，考生不能擅自取用、传递个人物品。
            </div>
            <div class="exam_notice_txt">4. 入场后按准考证上指定的考位号入座，将《准考证》、身份证件放在桌子左上角以便核验。涉及试题内容的疑问，不得提问。</div>
            <div class="exam_notice_txt">5. 迟到15分钟后不准进入考场参加考试，不得提前交卷。</div>
            <div class="exam_notice_txt">6. 本次考试两个科目统一计时，共计180分钟，请合理安排每个科目的作答时间。</div>
            <div class="exam_notice_txt">7. 若考试机出现故障，考生须举手示意，向监考人员报告。考生不得随意触碰电脑开关机键，不得有故意关机或自行重新启动计算机及其他恶意操作行为。</div>
            <div class="exam_notice_txt">8. 考生要自觉遵守考场秩序，保持安静。如有不能坚持考试的，应报告监考人员，监考人员将根据具体情况进行处理。考生在考试期间如需上厕所，应向监考人员举手示意，在得到监考人员的允许后，由监考人员陪同出入考场。
            </div>
            <div class="exam_notice_txt">9.
                考试结束后，考生须根据监考员指令依次退出考场，不准在考场逗留。草稿纸不得带离考场。
            </div>
            <div class="exam_notice_txt">10. 如不遵守考场纪律，不服从考试工作人员管理或有违纪、作弊等行为的，将按照《国家教育考试违规处理办法》进行记录和处理，并提供给当地教育部门。</div>
        </div>
    </div>
</template>

<script>
    import {getPdfInfo, getPdfInfoNoLogin} from "r/index/pdf.js";

    export default {
        name: "PdfArea",
        props: {
            type: [Number, String]
        },
        data() {
            return {
                dataInfo: {
                    avatar: null,
                    zkz_num: null,
                    name: null,
                    sfz: null,
                    bm_num: null,
                    university_name: null,
                    examRoomName: null,
                    examRoomAddress: null,
                    time: null,
                    subject: null,
                    location: null,
                    pc_no: null,
                },
                tableList: [],
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            getUrlParams(key) {
                let url = window.location.search.substr(1);
                if (url == '') {
                    return false;
                }
                let paramsArr = url.split('&');
                for (let i = 0; i < paramsArr.length; i++) {
                    let combina = paramsArr[i].split("=");
                    if (combina[0] == key) {
                        return combina[1];
                    }
                }
                return false;
            },
            getInfo() {
                if (this.type == 1) {
                    this.getPdfInfo();
                } else {
                    this.getPdfInfoNoLogin();
                }
            },
            // 获取pdf信息
            getPdfInfo() {
                this.requestCom(getPdfInfo, {}, 1)
            },
            // 获取pdf信息-无需登录
            getPdfInfoNoLogin() {
                let idcard = this.getUrlParams('idcard');
                if (!idcard) {
                    return this.$message.info('学生不存在');
                }
                this.requestCom(getPdfInfoNoLogin, {id_card: idcard}, 2)
            },
            // 公共请求方法
            async requestCom(request, data, type) {
                this.$store.commit('setAllLoading', true);
                let {data: res} = await request(data)
                if (res.code != 200) {
                    this.$message.error(res.message);
                    if (res.code == '9999' && type == 1) {
                        await this.$router.replace('/main/index')
                    }
                    this.$store.commit('setAllLoading', false);
                    return false;
                }
                let account = res.data.account;
                let examRoom = res.data.examroom;
                let exam_student = res.data.exam_student;
                let subject = exam_student.paper_type;
                // let subject = account.subject1;
                // if (account.subject2) {
                //     subject += '、' + account.subject2;
                // }
                // if (account.subject3) {
                //     subject += '、' + account.subject3;
                // }
                // let avatar = 'https://' + exam_student.avatar_bucket + '.cos.' + exam_student.avatar_endpoint + '.myqcloud.com/' + exam_student.avatar_key + '?imageMogr2/thumbnail/x300';
                let avatar = res.data.avatar + '?imageMogr2/thumbnail/x300';
                let location = exam_student.exam_room_address + ' ' + exam_student.exam_room_name;
                this.dataInfo = {
                    avatar: avatar,
                    zkz_num: exam_student.zkz_num,
                    name: account.name,
                    sfz: account.sfz,
                    bm_num: account.bm_num,
                    university_name: account.university_name,
                    examRoomName: examRoom.name,
                    examRoomAddress: examRoom.address,
                    time: '2024年6月12日 9:00-12:00',
                    subject: subject,
                    location: location,
                    pc_no: exam_student.pc_no,
                }
                this.tableList = [
                    {
                        time: '2024年6月12日 9:00-12:00',
                        subject: subject,
                        location: location,
                        pc_no: exam_student.pc_no,
                    }
                    
                ];
                this.$store.commit('setAllLoading', false);
            }
        },
    }
</script>

<style scoped lang="scss">
    .pdf_box {
        // width: 732px;
        // height: 1062px;
        width: 692px;
        height: 1022px;
        padding: 10px;
        border: 1px solid #000000;
        .title_big {
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            line-height: 36px;
            margin-top: 20px;
        }

        .student_info {
            position: relative;
            height: 238px;
            margin-top: 30px;

            .top {
                height: 204px;
            }

            .lf {
                float: left;
                width: 500px;
            }

            .rt {
                width: 120px;
                max-height: 180px;
                float: right;
                margin-top: 12px;
                margin-right: 50px;

                img {
                    width: 120px;
                    border: 1px solid #000000;
                    vertical-align: middle;
                }
            }

            .student_info_item {
                font-size: 15px;
                height: 24px;
                line-height: 24px;
                padding: 5px 0;

                .text_bold {
                    float: left;
                    width: 75px;
                    font-weight: bold;
                }

                .text_nol {
                    float: left;
                    width: 425px;
                    font-weight: normal;

                    &.big {
                        width: 525px;
                    }
                }
            }
        }

        .student_table {
            width: 100%;
            font-size: 15px;
            text-align: left;
            margin-top: 20px;
            border-top: 1px solid #000000;
            border-left: 1px solid #000000;

            th, td {
                width: 25%;
                padding: 10px 5px;
                border-right: 1px solid #000000;
                border-bottom: 1px solid #000000;
            }

            .tr_template:nth-child(1) td {
                padding: 0;
                border: 0;
            }
        }

        .exam_notice {
            font-size: 16px;
            margin-top: 20px;
            line-height: 28px;
            text-align: left;

            .exam_notice_tit {
                font-weight: bold;
            }

            .exam_notice_txt {
                font-size: 16px;
                font-weight: normal;
                line-height: 24px;
            }
        }
    }
</style>
